import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabModal } from "@CabComponents/CabModal";
import { Box, DialogContentText, Divider, Snackbar, Typography } from "@mui/material";
import colors from "../../../colors";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import { FetchReturnClientError, Meeting } from "../../../store";
import { DateTime } from "luxon";
import { humanReadableDuration } from "../../../utils/scheduleUtils";
import BookMeetingErrorModal from "../BookMeetingErrorModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import EditLocationsModal from "../EditLocationsModal";
import { useState } from "react";
import CabSpinner from "@CabComponents/CabSpinner";
import { IoCopyOutline, IoCreateOutline } from "react-icons/io5";
import EditParticipantsModal from "../EditParticipantsModal";

export interface BookMeetingModalProps {
  open: boolean;
  isBooking: boolean;
  meeting: Meeting;
  allowEditAttendees: boolean;
  start?: DateTime;
  end?: DateTime;
  locations: string[];
  attendeeEmails: {key: number, value: string}[];
  bookingConfirmationMessage: string | null;
  bookingError: FetchReturnClientError<string> | null;
  onClearBookingConfirmationMessage: () => void;
  confirmBookMeeting: () => Promise<void>;
  onClose: () => void;
  onClearBookingError: () => void;
  onCopyToClipboard: (textToCopy: string | undefined) => Promise<void>;
  onSaveMeeting: (title: string, description: string) => Promise<void>;
}

export const BookMeetingModal = ({
  open, isBooking, meeting, allowEditAttendees, start, end, bookingConfirmationMessage, locations, attendeeEmails, 
  bookingError, confirmBookMeeting, onClose, onClearBookingError, onCopyToClipboard, 
  onClearBookingConfirmationMessage, onSaveMeeting
}: BookMeetingModalProps) => {
  const [openEditTitleModal, setOpenEditTitleModal] = useState(false);
  const [openEditDescriptionModal, setOpenEditDescriptionModal] = useState(false);
  const [openEditParticipantsModal, setOpenEditParticipantsModal] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [openEditLocationsModal, setOpenEditLocationsModal] = useState(false);
  const [openRebookAlert, setOpenRebookAlert] = useState(false);

  let bookDate: DateTime | null = null;
  if (meeting.event_start_time) {
    bookDate = DateTime.fromISO(meeting.event_start_time);
  }
  const bookedTime = bookDate ? `${bookDate.setLocale('en-us').toLocaleString({
    weekday: 'long', month: 'long', day: 'numeric'})} at ${bookDate.toFormat('h:mma').toLowerCase()}` : '';

  const handleOpenEditModal = (field: string) => {
    if (field === 'Meeting Title') {
      setTitle(meeting.title || '');
      setDescription(meeting.description || '');
      setOpenEditTitleModal(true);
    } else if (field === 'Meeting Description') {
      setDescription(meeting.description || '');
      setTitle(meeting.title || '');
      setOpenEditDescriptionModal(true);
    } else if (field === 'Locations' || field === 'Calendar') {
      setOpenEditLocationsModal(true);
    } else if (field === 'Meeting Attendees') {
      setOpenEditParticipantsModal(true);
    }
  };

  const handleCloseEditTextModal = () => {
    setTitle('');
    setDescription('');
    setOpenEditTitleModal(false);
    setOpenEditDescriptionModal(false);
  };

  const handleSaveMeeting = async () => {
    await onSaveMeeting(title, description);
    handleCloseEditTextModal();
  };

  const handleSendInvatation = (rebooking?: boolean) => {
    if (!meeting.event_start_time || rebooking) {
      setOpenRebookAlert(false);
      confirmBookMeeting();
    } else {
      setOpenRebookAlert(true);
    }
  };
  
  return <>
    <CabModal
      title={'Book Meeting'}
      open={open}
      onClose={onClose}
      closeIcon
      aria-labelledby="confirm-poll-event"
      aria-describedby="confirm-poll-event"
      actionButtons={(
        <CabButton 
          onClick={() => handleSendInvatation()} 
          disabled={isBooking}
        >
          {isBooking 
            ? <CabSpinner scale={1} />
            : <span>Send Invitation</span>
          }
        </CabButton>
      )}
      sx={{ '& .MuiDialog-paper': { width: 575 } }}
    >
      <Divider sx={{ marginBottom: 2 }} />
      <Box display="flex" flexDirection="column" gap={'14px'}>
        {meeting ? (
          [
            { name: 'Meeting Title', value: meeting.title_booked || meeting.title, copyButton: true, editButton: true },
            { name: 'Meeting Description', value: meeting.description, 
              copyButton: true, editButton: true },
            {
              name: 'Meeting Time',
              value: `${start?.toLocaleString({ weekday: 'short', month: 'long', day: 'numeric' })}:
              ${start?.toLocaleString({ hour: 'numeric', hour12: true, minute: 'numeric', })
            .toLowerCase().split(' ').join('')}
              - ${end?.toLocaleString({ hour: 'numeric', hour12: true, minute: 'numeric' })
            .toLowerCase().split(' ').join('')}
            ${start?.toLocaleParts({ timeZoneName: 'short' })
            .find(p => p.type === 'timeZoneName')?.value}`,
            },
            { name: 'Duration', value: humanReadableDuration(meeting.duration_minutes || 0) },
            { name: 'Calendar', value: meeting.calendar_info?.calendar_name, editButton: true },
            { 
              name: 'Locations',
              valueListLocation: locations,
              editButton: true
            },
            {
              name: 'Meeting Attendees',
              valueList: attendeeEmails,
              copyButton: true,
              editButton: allowEditAttendees,
            },
          ].map(section => (
            <Box key={section.name}>
              <Box display="flex" justifyContent="space-between">
                <Box display='flex' gap={'6px'} alignItems='flex-end'>
                  <DialogContentText fontWeight={700} color={colors.black900}>
                    {section.name}
                  </DialogContentText>
                  {section.editButton && (
                    <CabIcon
                      alt={`Edit ${section.name}`}
                      Icon={IoCreateOutline}
                      sx={{ fontSize: 22, color: colors.greenPrimary, marginBottom: '1px' }}
                      onClick={() => handleOpenEditModal(section.name)}
                    />
                  )}
                </Box>
                {section.copyButton && (
                  <CabIcon
                    alt={`Copy ${section.name}`}
                    Icon={IoCopyOutline}
                    sx={{ fontSize: 26, color: colors.greenPrimary }}
                    onClick={() => onCopyToClipboard(section.valueList
                      ? section.valueList.map(v => v.value).join(', ')
                      : section.value
                    )}
                  />
                )}
              </Box>
              {section.valueList ? (
                <Box display="flex" gap={1} flexWrap="wrap" marginTop={1}>
                  {section.valueList.map(value => (
                    <CabToggleChip key={value.key} label={value.value} selected />
                  ))}
                </Box>
              ) : (
                section.valueListLocation ? (
                  <ul style={{paddingLeft: 24, margin: 0}}>
                    {section.valueListLocation.map((locationName, idx) => {
                      return <li key={idx}>
                        <DialogContentText color={colors.black900} marginTop={0.5}>
                          {locationName}<br />
                        </DialogContentText>
                      </li>;
                    })}
                  </ul>
                ) : (
                  <DialogContentText color={colors.black900} marginTop={0.5}>
                    {section.value}
                  </DialogContentText>
                ))}
            </Box>
          ))
        ) : null}
      </Box>
    </CabModal>
    <Snackbar
      open={!!bookingConfirmationMessage}
      onClose={onClearBookingConfirmationMessage}
      autoHideDuration={4000}
      message={bookingConfirmationMessage}
      sx={{ marginTop: '96px' }}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
    />
    <BookMeetingErrorModal
      bookingError={bookingError}
      clearError={onClearBookingError}
    />
    <CabModal
      open={openEditTitleModal || openEditDescriptionModal}
      onClose={handleCloseEditTextModal}
      title={openEditTitleModal ? 'Edit Title' : 'Edit Description'}
      closeIcon
      actionButtons={
        <Box>
          <CabButton buttonType="secondary" sx={{ marginRight: 1 }} onClick={handleCloseEditTextModal}>
            Cancel
          </CabButton>
          <CabButton disabled={!title} onClick={() => handleSaveMeeting()}>
            Save
          </CabButton>
        </Box>
      }
    >
      {openEditTitleModal && (
        <CabTextInput
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value || '')}
        />
      )}
      {openEditDescriptionModal && (
        <CabTextInput
          fullWidth
          maxRows={4}
          minRows={1}
          value={description}
          onChange={(e) => setDescription(e.target.value || '')}
          multiline
        />
      )}
    </CabModal>
    <EditLocationsModal
      meetingId={meeting.id}
      onModalClose={() => setOpenEditLocationsModal(false)}
      modalOpen={openEditLocationsModal}
    />
    <EditParticipantsModal
      meetingId={meeting.id}
      onClose={() => setOpenEditParticipantsModal(false)}
      open={openEditParticipantsModal}
    />
    <CabModal
      open={openRebookAlert}
      onClose={() => setOpenRebookAlert(false)}
      isAlert
      title={'Duplicate Booking Warning'}
      // text={`This event has been already been booked for ${bookedTime}.
      // Are you sure you want to book a duplicate event?`}
      actionButtons={<Box>
        <CabButton buttonType="secondary" sx={{ marginRight: 1 }} onClick={() => setOpenRebookAlert(false)}>
          Cancel
        </CabButton>
        <CabButton onClick={() => handleSendInvatation(true)}>
          Yes
        </CabButton>
      </Box>}
    >
      <Box display='flex' flexDirection='column' gap={2}>
        <Box display='flex' flexDirection='column'>
          <Typography textAlign='center' color={colors.black800}>
            This meeting was previously booked for:
          </Typography>
          <Typography textAlign='center' color={colors.black800} fontWeight={700}>{bookedTime}</Typography>
        </Box>
        <Typography textAlign='center' color={colors.black800}>
          Re-booking this meeting will create a new calendar event. Are you sure?
        </Typography>
      </Box>
    </CabModal>
  </>;
};